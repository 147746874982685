/* tslint:disable */
/* eslint-disable */
/**
 * Trendtours CMS API
 * ISO API for the IBE. 
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiPromotionDataRequest
 */
export interface ApiPromotionDataRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiPromotionDataRequest
     */
    rkid?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPromotionDataRequest
     */
    promotionCandidate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPromotionDataRequest
     */
    basePrice?: string;
}

export function ApiPromotionDataRequestFromJSON(json: any): ApiPromotionDataRequest {
    return ApiPromotionDataRequestFromJSONTyped(json, false);
}

export function ApiPromotionDataRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPromotionDataRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rkid': !exists(json, 'rkid') ? undefined : json['rkid'],
        'promotionCandidate': !exists(json, 'promotionCandidate') ? undefined : json['promotionCandidate'],
        'basePrice': !exists(json, 'basePrice') ? undefined : json['basePrice'],
    };
}

export function ApiPromotionDataRequestToJSON(value?: ApiPromotionDataRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rkid': value.rkid,
        'promotionCandidate': value.promotionCandidate,
        'basePrice': value.basePrice,
    };
}


// custom code here ##################################################################################################################
function toUTC(date) {
  var date_utc =  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
  return new Date(date_utc);
}
